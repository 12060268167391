import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()

export class HttpIntercepter implements HttpInterceptor {
    constructor(private router: Router) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let headers = new HttpHeaders();
        headers.set('Allow-access-control-origin', '*');
        if (localStorage.getItem('userData')) {
            const token = JSON.parse(localStorage.getItem('userData'));
            if (!token) {
                localStorage.clear();
                this.router.navigate(['/home']);
            }
            //console.log('REQUEST IS');

            //console.log(req.url);
            if (req.url.includes('lumenreportingapi') ||
                req.url.includes('LumenReportingAPI') ||
                req.url.includes('RedisData')) {
                headers = new HttpHeaders({ JWTtoken: 'Bearer ' + token.accessToken });
            }
            else if (req.url.includes('onBoardLumenUserNlp') || req.url.includes('provisioningSARAUser')) {
                headers = new HttpHeaders(
                    {
                        Authorization: 'Bearer ' + token.accessToken,
                        BookKey: 'psnXDZc1F0SqMxyUgMtsng',
                        AuthorizationKey: 'jdjd12587984545@dhjdh'
                    });
            }
            else if(req.url.includes('lumenwebapi')) {
                headers = new HttpHeaders(
                    {
                        jwttoken: 'Bearer ' + token.accessToken
                    }
                );
            }
            else if(req.url.includes('https://api.ipify.org/?format=json')){
                headers = new HttpHeaders({});
            }
            else if(req.url.includes('ticket/core/ticket/create/support') ||
                req.url.includes('ticket/core/productService/findAllActive') ||
                req.url.includes('ticket/core/category/findAllActive') ||
                req.url.includes('ticket/core/priority/findAllActive')){
                headers = new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('supportToken')});
            } else {
                headers = new HttpHeaders({ Authorization: 'Bearer ' + token.accessToken });
            }
            headers.append('Content-Security-Policy',
                'trusted-types angular angular#unsafe-bypass; require-trusted-types-for \'script\';');
        }
        else {
            if(req.url.includes('fetchOrgListforUser')) {
                headers = new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('tToken') });
            } 
            if(req.url.includes('fetchOrgListforUserV1')) {
                headers = new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('tToken') });
            } 
            if(req.url.includes('nsAuthAPI')) {
                headers = new HttpHeaders({ Authorization: 'Bearer ' + localStorage.getItem('tToken') });
            }
            // if(!this.router.url.includes('/reset-password') && !req.url.includes('fetchOrgListforUser') && !req.url.includes('nsAuthAPI') && !req.url.includes('sendOTPToLoginUser') && !req.url.includes('getOtpForInviteUser') && !req.url.includes('verifyOTP')){
            //     // localStorage.clear();
            //     this.router.navigate(['/home']);
            // }
            if(!this.router.url.includes('/reset-password') && !req.url.includes('fetchOrgListforUser') && !req.url.includes('nsAuthAPI') && !req.url.includes('sendOTPToLoginUser') && !req.url.includes('getOtpForInviteUser') && !req.url.includes('verifyOTP')){
                // localStorage.clear();
                this.router.navigate(['/home']);
            }
        }
        const newRequest = req.clone({
            headers
        });

        return next.handle(newRequest).pipe(
            catchError(er => {
                const url =  er.url;
                //check 401 only for NorthStar API and
                const check = url.includes('/nsg/') ||
                                url.includes('/nsauth/') ||
                                url.includes('/uam/') ||
                                url.includes('/BDU/') ||
                                url.includes('/tempoebop/');
                if (er.status === 401 && check) {
                    localStorage.clear();
                    this.router.navigate(['/home']);
                }
                //console.log('I am in intercept');
                return throwError(er);
            })
        );
    }
}

/* delete it
req.url === 'https://lumen-ns.straviso.net/LumenReporting/api/GetBotStatus' ||
               req.url === 'https://lumen-ns.straviso.net/LumenReporting/api/GetBotTransactionAccumulated' ||
               req.url === 'https://lumen-ns.straviso.net/LumenRedisKafka/api/RedisData' ||
               req.url === 'https://lumen-ns.straviso.net/LumenReporting/api/GetBotAverageHandlingTime' ||

               */
